<template>
  <div class="register site__centered">

    <div class="form__wrap register__form">
      <h2 class="form__title capitalize">{{ userRole }} Account Registration</h2>
      <p class="form__subtitle">Please register for a {{ userRole }} account.</p>

      <b-spinner label="Loading" v-if="isLoading"></b-spinner>

      <div
        v-else
      >
        <RegistrationFormRow
          v-for="field in preparedFields"
          v-show="!field.hidden"
          :key="field.id"
          :fields-data="field"
        />

        <label
          v-if="authSettings.use_terms_and_conditions"
          class="center nice-checkbox"
          :class="{'is-invalid': $v.terms.$dirty && !$v.terms.sameAs }"
        >
          <input
            v-model="terms"
            @change="$v.terms.$touch()"
            type="checkbox"
          />
          <span></span>
          <a
            href="#"
            class="terms-and-conditions-link"
            @click="isShowTermsModal = true"
          >
            I agree to terms & conditions
          </a>
        </label>

      </div>

      <p v-if="errorMessage"
         class="form__error pt-4"
      >
        {{ errorMessage }}
      </p>

      <p v-if="serverMessage"
         class="form__error pt-4"
      >
        {{ serverMessage }}
      </p>

      <button
        class="btn btn--color-dark form__button"
        :class="{ 'btn--loading': isLoading }"
        @click="sendForm"
        :disabled="isLoading"
      >
        <span v-if="!isLoading">Register Account</span>
      </button>
      <span class="center">Or</span>
      <router-link
        :to="{ name: 'auth-login', params: { return: returnPageRouteName, routeParams: returnPageRouteParams, role: chosenRole }}"
        class="btn btn--color-outline-bright form__button"
        :disabled="isLoading"
      >
        <span>Login Account</span>
      </router-link>
    </div>

    <TermsAndConditionalModal
      :is-show-modal="isShowTermsModal"
      @closeModal="isShowTermsModal = false"
    />

  </div>
</template>

<script>
  import store from '@/store'
  import useJwt from '@/auth/jwt/useJwt'

  import { BSpinner } from 'bootstrap-vue'

  import "maz-ui/lib/css/base.css"
  import "maz-ui/lib/css/maz-phone-number-input.css"

  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  import { mapGetters } from 'vuex'
  import { sameAs } from 'vuelidate/lib/validators'
  import { prepareProgramRegistrationInfo } from '@core/mixins/prepareProgramRegistrationInfo'

  import RegistrationFormRow from '@/views/Registration/RegistrationFormRow.vue'
  import TermsAndConditionalModal from '@/components/site/TermsAndConditionalModal.vue'

  export default {
    name: 'Register',
    components: {
      TermsAndConditionalModal,
      RegistrationFormRow,
      BSpinner,
    },
    mixins: [prepareProgramRegistrationInfo],
    data() {
      return {
        returnPageRouteName: null,
        returnPageRouteParams: null,
        chosenRole: null,
        formData: {
          userEmail: null,
          userFirstName: null,
          userLastName: null,
          gender: null,
          phone: null,
          password: null,
          passwordRepeat: null,
          termsAccepted: false,
        },
        terms: false,
        isShowTermsModal: false,
      }
    },
    validations: {
      terms: {
        sameAs: sameAs(() => true)
      },
    },
    computed: {
      ...mapGetters({
        familyId: 'family/getFamilyId',
        authSettings: 'authSettings/getAuthSettings',
        roles: 'registration/getRoles',
        preparedFields: 'registration/getPreparedFields',
        hasFileField: 'registration/getHasFileField',
        hasSignatureField: 'registration/getHasSignatureField',
        registrationForm: 'registration/getRegistrationForm',
      }),
      isLoading: {
        get: function () {
          return store.getters['registration/getIsLoading']
        },
        set: function (val) {
          store.commit('registration/SET_IS_LOADING', val)
        }
      },
      userRole: {
        get: function () {
          return store.getters['registration/getUserRole']
        },
        set: function (val) {
          store.commit('registration/SET_USER_ROLE', val)
        }
      },
      errorMessage: {
        get: function () {
          return store.getters['registration/getErrorMessage']
        },
        set: function (val) {
          store.commit('registration/SET_ERROR_MESSAGE', val)
        }
      },
      serverMessage: {
        get: function () {
          return store.getters['registration/getServerMessage']
        },
        set: function (val) {
          store.commit('registration/SET_SERVER_MESSAGE', val)
        }
      },
      validTracker: {
        get: function () {
          return store.getters['registration/getValidTracker']
        },
        set: function (val) {
          store.commit('registration/SET_VALID_TRACKER', val)
        }
      },
    },
    mounted () {
      if (!this.authSettings.enable_regular_registration_for_staff && !this.authSettings.enable_regular_registration_for_parents) {
        this.$router.push({name: 'auth-login'})
      }
    },
    async created() {
      this.isLoading = false
      if (this.$route.params.role) {
        this.chosenRole = this.$route.params.role
      }
      if (!this.chosenRole) {
        await this.$router.push({name: 'auth-login'})
      }
      if (!this.availableRegularRegistration(this.chosenRole, this.authSettings)) {
        await this.$router.push({name: 'auth-login'})
      }
      if (this.$route.params.return){
        this.returnPageRouteName = this.$route.params.return
      }
      if (this.$route.params.routeParams){
        this.returnPageRouteParams = this.$route.params.routeParams
      }
      if (this.$route.query.email){
        this.formData.userEmail = this.$route.query.email
      }
      if (this.$route.query.family){
        store.commit('family/SET_FAMILY_ID', this.$route.query.family)
      }
      await store.commit('registration/SET_AUTH_SETTINGS', this.authSettings)
      await store.dispatch('registration/prepareRoleOptions')
      await this.setRole()

      await this.setRegistrationForm()
      await this.setFormsData()

      this.isLoading = false
    },
    methods: {
      async setRole() {
        if (this.chosenRole) {
          this.userRole = this.chosenRole
        } else {
          this.userRole = this.$config.enable_student_signup ? 'student' : 'parent'
        }
      },
      async setRegistrationForm() {
        await store.dispatch('registration/fetchSchoolRegistrationForm', {
          role: this.userRole,
          query: null,
        })
      },
      async setFormsData() {
        store.commit('registration/SET_FORMS_DATA')
        store.commit('registration/CHECK_CONDITIONS')
      },
      async validStep() {
        if (this.$v.$invalid){
          await this.$v.$touch()

          if (this.authSettings.use_terms_and_conditions && this.$v.terms.$invalid){
            this.errorMessage = 'Please, agree to out terms and conditions'
            this.validTracker = true
            return
          }
        }

        if (this.preparedFields.filter(field =>
             field.required
          && !['plain_text', 'signature'].includes(field.type)
          && (field.value == null || field.value.length === 0)
          && !field.hidden).length > 0
        ) {
          this.errorMessage = 'Please, fill required fields'
          this.validTracker = true
          return
        }

        const signatureFields = this.preparedFields.filter(field => field.type === 'signature' && field.required && !field.hidden)
        if (signatureFields.length > 0 ) {
          signatureFields.forEach(field => {
            const canvasRef = this.findCanvasRefForField(field)
            if (canvasRef && canvasRef.isEmpty() && !canvasRef.loadedImage) {
              this.errorMessage = 'Please, fill required fields'
              this.validTracker = true
            }
          })
          if (this.validTracker) {
            return
          }
        }

        this.validTracker = false

        this.errorMessage = ''
        this.serverMessage = ''
      },
      async sendForm() {
        this.validTracker = false
        await this.validStep()
        if (this.validTracker) return

        await this.register()
      },
      async register() {
        this.isLoading = true

        let fileFieldsCount = 0

        let queryParams = {
          fields: [],
          form_data: {
            role: this.userRole,
            family_id: this.familyId,
          },
          registration_form_id: this.registrationForm.id,
        }

        const filesLinks = []
        const filesNames = []

        if (this.hasFileField) {
          const fileFields = this.preparedFields.filter(row => row.type === 'file_upload' && row.value)

          if (fileFields.length > 0) {
            fileFields.forEach(field => {
              Array.from(field.value).forEach(val => {
                if (val instanceof Blob) {
                  filesLinks.push(this.uploadFile(val))
                } else {
                  filesNames.push(val)
                }
              })
            })
          }
        }

        if (this.hasSignatureField) {
          const signatureFields = this.preparedFields.filter(row => row.type === 'signature')
          signatureFields.forEach(field => {
            const canvasRef = this.findCanvasRefForField(field)
            if (canvasRef && !canvasRef.isEmpty()) {
              field.signature = canvasRef.save()
            }
          })
        }

        Promise.all(filesLinks)
          .then(responses => responses.forEach(
            response => {
              if (!response.file_path) {
                store.commit('SET_LOADING_STATUS', false)
                this.errorMessage = 'Error uploading file'
                throw new Error('Error')
              }
              filesNames.push(response.file_path)
            },
          ))
          .then(() => {

            this.preparedFields
              .filter(row => !row.hidden)
              .forEach(row => {
                if (row.type === 'file_upload' && row.value) {
                  let from = fileFieldsCount
                  let to = fileFieldsCount + row.value.length
                  let sliced = filesNames.slice(from, to)

                  queryParams.fields.push({
                    field_id: row.id,
                    files: sliced,
                  })
                  queryParams.form_data[row.name] = sliced

                  fileFieldsCount = to
                }  else if (row.type === 'signature' && row.signature) {
                  queryParams.fields.push({
                    field_id: row.id,
                    result: row.signature,
                  })
                  queryParams.form_data[row.name] = row.signature
                } else {
                  queryParams.fields.push({
                    field_id: row.id,
                    result: row.value,
                  })
                  queryParams.form_data[row.name] = row.value
                }
              })

            useJwt.register(queryParams)
              .then(response => {
                const {userData} = response.data

                if (!userData.is_approved) {
                  this.serverMessage = 'Your account is under review, we will email you once your account has been approved.'
                  return
                }

                useJwt.setToken(response.data.accessToken)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)

                this.$store.commit('CHANGE_USER_STATUS', userData.id)
                this.$store.commit('SET_USER_DATA', userData)

                if (this.returnPageRouteName) {
                  if (userData.role === 'parent' && this.returnPageRouteName === 'application-create') {
                    this.$router.replace({ name: 'application-select-child', params: this.returnPageRouteParams})
                    return
                  }
                  this.$router.replace({ name: this.returnPageRouteName, params: this.returnPageRouteParams})
                } else {
                  this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                }
              })
              .catch(err => {
                if (err.response) {
                  if ( err.response.status === 401 ) this.serverMessage = 'Incorrect email address and / or password'
                  if ( err.response.status === 403 ) this.serverMessage = err.response.data.message
                  if ( err.response.status === 422 ) {
                    const serverMessage = Object.values(err.response.data.errors)
                    this.serverMessage = serverMessage.flat(Infinity).join(' ')
                  }
                }
              })
              .finally(() => {
                this.isLoading = false
              })
          })
      },
      findCanvasRefForField(field) {
        return this.$refs[field.ref][0]?.$refs[`signature_${field.ref}`]?.$refs[field.ref]
      },
      async uploadFile(file) {
        const formData = new FormData()
        formData.append('file', file)

        return store.dispatch('files/uploadFiles', {
          name: `application-files-${new Date().getTime()}`,
          file: formData,
        })
      },
    },
  }
</script>

<style scoped>
.error {
  position: absolute;
  color: red;
  font-size: 14px;
}
.terms-error{
  color: red;
}
</style>

<style lang="scss">
.phone__row{
  width: 100%;
  margin-bottom: 30px;
}
.terms-and-conditions-link {
  cursor: pointer;
  padding-left: 27px;
  color: #000;
}
.phone-number {
  border-radius: 12px;
  .maz-input {
    height: 60px;
    background: #EDEDED;
    border: none !important;
    border-color: none !important;
  }
  .maz-input__toggle-btn {
    display: none;
  }
  &.is-invalid{
    border: 1px solid var(--error) !important;
    &::placeholder {
      color: var(--error)
    }
  }
}
</style>
