var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showTextField(_vm.fieldsData.type))?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),((_vm.fieldsData.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
              'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
              'disabled': _vm.isDisabledEditing
            },attrs:{"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"required":_vm.fieldsData.required,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.fieldsData.value)?_vm._i(_vm.fieldsData.value,null)>-1:(_vm.fieldsData.value)},on:{"input":_vm.validate,"change":[function($event){var $$a=_vm.fieldsData.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fieldsData, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fieldsData, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fieldsData, "value", $$c)}},_vm.emitChanges]}}):((_vm.fieldsData.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
              'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
              'disabled': _vm.isDisabledEditing
            },attrs:{"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"required":_vm.fieldsData.required,"type":"radio"},domProps:{"checked":_vm._q(_vm.fieldsData.value,null)},on:{"input":_vm.validate,"change":[function($event){return _vm.$set(_vm.fieldsData, "value", null)},_vm.emitChanges]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
              'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
              'disabled': _vm.isDisabledEditing
            },attrs:{"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"required":_vm.fieldsData.required,"type":_vm.fieldsData.type},domProps:{"value":(_vm.fieldsData.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsData, "value", $event.target.value)},_vm.validate],"change":_vm.emitChanges}})]):_vm._e(),(_vm.fieldsData.type === 'plain_text')?_c('div',{staticClass:"plain-text"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.fieldsData.label)}})]):_vm._e(),(_vm.fieldsData.type === 'email')?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
              'is-invalid':
                 (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value)
              || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required)
              || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.email)
              ,
              'disabled': _vm.isDisabledEditing
            },attrs:{"type":"email","name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"required":_vm.fieldsData.required},domProps:{"value":(_vm.fieldsData.value)},on:{"blur":_vm.validate,"change":_vm.emitChanges,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsData, "value", $event.target.value)}}}),(_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.email)?_c('p',{staticClass:"error"},[_vm._v(" Valid email required ")]):_vm._e()]):_vm._e(),(_vm.fieldsData.type === 'tel')?_c('label',{staticClass:"phone__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('MazPhoneNumberInput',{staticClass:"phone-number",class:{
        'is-invalid':
           (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value)
        || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required)
        || (_vm.enteredPhoneNumber && !_vm.enteredPhoneNumber.isValid),
        'disabled': _vm.isDisabledEditing
      },attrs:{"value":_vm.fieldsData.value,"no-flags":"","default-phone-number":_vm.defaultPhoneNumber,"disabled":_vm.isDisabledEditing,"no-validation":""},on:{"update":function($event){return _vm.emitPhoneNamberChanges($event)}}}),(_vm.enteredPhoneNumber && !_vm.enteredPhoneNumber.isValid)?_c('p',{staticClass:"error"},[_vm._v(" Valid phone number is required ")]):_vm._e()],1):_vm._e(),(_vm.fieldsData.type === 'long_text')?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],class:{
              'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
              'disabled': _vm.isDisabledEditing
            },attrs:{"required":_vm.fieldsData.required,"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing},domProps:{"value":(_vm.fieldsData.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsData, "value", $event.target.value)},_vm.validate],"change":_vm.emitChanges}})]):_vm._e(),(_vm.fieldsData.type === 'date')?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('date-picker',{class:{
              'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
              'disabled': _vm.isDisabledEditing
            },attrs:{"input-class":"input-date","valueType":"format","placeholder":"Select","disabled":_vm.isDisabledEditing},on:{"input":_vm.validate,"change":_vm.emitChanges},model:{value:(_vm.fieldsData.value),callback:function ($$v) {_vm.$set(_vm.fieldsData, "value", $$v)},expression:"fieldsData.value"}})],1):_vm._e(),(_vm.fieldsData.type === 'dob')?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('date-picker',{class:{
              'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
              'disabled': _vm.isDisabledEditing
            },attrs:{"input-class":"input-date","valueType":"format","placeholder":"Select","disabled":_vm.isDisabledEditing,"disabled-date":function (date) { return date >= new Date(); }},on:{"input":_vm.validate,"change":_vm.emitChanges},model:{value:(_vm.fieldsData.value),callback:function ($$v) {_vm.$set(_vm.fieldsData, "value", $$v)},expression:"fieldsData.value"}})],1):_vm._e(),(_vm.fieldsData.type === 'dropdown')?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"input-select"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
                'is-invalid': (_vm.fieldsData.required && _vm.error && (_vm.fieldsData.value == null || _vm.fieldsData.value.length == 0)) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
                'disabled': _vm.isDisabledEditing
              },attrs:{"disabled":_vm.isDisabledEditing,"required":_vm.fieldsData.required},on:{"blur":_vm.$v.fieldsData.value.$touch,"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fieldsData, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.emitChanges]}},_vm._l((_vm.fieldsData.options),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v(_vm._s(option.text))])}),0)])]):_vm._e(),(_vm.fieldsData.type === 'multi_select')?_c('div',[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',[_c('v-select',{staticClass:"multiple-select",class:{'is-invalid': (_vm.fieldsData.required && _vm.error && (_vm.fieldsData.value == null || _vm.fieldsData.value.length == 0)) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required) },attrs:{"placeholder":"Select","multiple":"","disabled":_vm.isDisabledEditing,"closeOnSelect":false,"options":_vm.fieldsData.options,"label":"name","deselectFromDropdown":true},on:{"input":_vm.emitChanges},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var name = ref.name;
return [_c('span',[_vm._v(_vm._s(name))])]}}],null,false,214010530),model:{value:(_vm.fieldsData.value),callback:function ($$v) {_vm.$set(_vm.fieldsData, "value", $$v)},expression:"fieldsData.value"}})],1)]):_vm._e(),(_vm.fieldsData.type === 'checkbox')?_c('div',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_vm._l((_vm.fieldsData.options),function(option,index){return _c('label',{key:index,staticClass:"nice-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],class:{
          'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
          'disabled': _vm.isDisabledEditing
        },attrs:{"type":"checkbox","disabled":_vm.isDisabledEditing},domProps:{"value":option,"checked":Array.isArray(_vm.fieldsData.value)?_vm._i(_vm.fieldsData.value,option)>-1:(_vm.fieldsData.value)},on:{"input":_vm.validate,"change":[function($event){var $$a=_vm.fieldsData.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fieldsData, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fieldsData, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fieldsData, "value", $$c)}},_vm.emitChanges]}}),_c('span',[_vm._v(_vm._s(option))])])})],2):_vm._e(),(_vm.fieldsData.type === 'radio_button')?_c('div',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label",class:{
          'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
        }},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_vm._l((_vm.fieldsData.options),function(option,index){return _c('label',{key:index,staticClass:"nice-radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],class:{
          'is-invalid': (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value) || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required),
          'disabled': _vm.isDisabledEditing
        },attrs:{"type":"radio","disabled":_vm.isDisabledEditing},domProps:{"value":option,"checked":_vm._q(_vm.fieldsData.value,option)},on:{"change":[function($event){return _vm.$set(_vm.fieldsData, "value", option)},_vm.emitChanges]}}),_c('span',[_vm._v(_vm._s(option))])])})],2):_vm._e(),(_vm.fieldsData.type === 'file_upload')?_c('div',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('file-select',{class:{
        'is-invalid': (_vm.fieldsData.required && _vm.error && (_vm.fieldsData.value == null || _vm.fieldsData.value.length === 0)),
        'disabled': _vm.isDisabledEditing
      },attrs:{"fields-data":_vm.fieldsData,"disabled":_vm.isDisabledEditing},on:{"input":_vm.validate,"addFiles":_vm.addFiles}}),(_vm.fieldsData.value && !_vm.isDisabledEditing)?_c('div',{staticClass:"files"},_vm._l((_vm.fieldsData.value),function(file,index){return _c('div',{key:index,staticClass:"file"},[_c('FileSVG'),(_vm.isFile(file))?_c('span',[_vm._v(_vm._s(file.name))]):_c('a',{attrs:{"href":_vm.findFileUrlByPath(file),"target":"_blank"}},[_vm._v(_vm._s(_vm.findFileNameByPath(file)))]),_c('span',{staticClass:"file_remove",on:{"click":function($event){return _vm.fileRemove(index)}}})],1)}),0):_vm._e()],1):_vm._e(),(_vm.fieldsData.type === 'signature')?_c('div',[_c('signature',{ref:'signature_' + _vm.fieldsData.ref,attrs:{"fields-data":_vm.fieldsData,"is-disabled-field":_vm.isDisabledEditing,"ref-name":_vm.fieldsData.ref}})],1):_vm._e(),(_vm.fieldsData.type === 'password')?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v(_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form__row-pass"},[((_vm.typePasword)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
          'is-invalid':
            (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value)
            || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.minLength)
            ,
          'disabled': _vm.isDisabledEditing
         },attrs:{"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"placeholder":"******","required":_vm.fieldsData.required,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.fieldsData.value)?_vm._i(_vm.fieldsData.value,null)>-1:(_vm.fieldsData.value)},on:{"input":_vm.validate,"change":[function($event){var $$a=_vm.fieldsData.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fieldsData, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fieldsData, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fieldsData, "value", $$c)}},_vm.emitChanges]}}):((_vm.typePasword)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
          'is-invalid':
            (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value)
            || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.minLength)
            ,
          'disabled': _vm.isDisabledEditing
         },attrs:{"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"placeholder":"******","required":_vm.fieldsData.required,"type":"radio"},domProps:{"checked":_vm._q(_vm.fieldsData.value,null)},on:{"input":_vm.validate,"change":[function($event){return _vm.$set(_vm.fieldsData, "value", null)},_vm.emitChanges]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.value),expression:"fieldsData.value"}],staticClass:"input",class:{
          'is-invalid':
            (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.value)
            || (_vm.fieldsData.required && _vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.minLength)
            ,
          'disabled': _vm.isDisabledEditing
         },attrs:{"name":_vm.fieldsData.name,"disabled":_vm.isDisabledEditing,"placeholder":"******","required":_vm.fieldsData.required,"type":_vm.typePasword},domProps:{"value":(_vm.fieldsData.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsData, "value", $event.target.value)},_vm.validate],"change":_vm.emitChanges}}),_c('span',{staticClass:"form__row-pass-show",on:{"click":function($event){return _vm.showPassword()}}},[_vm._v("Show")]),(_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.required)?_c('p',{staticClass:"error"},[_vm._v(" The Password field is required ")]):(_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.value.minLength)?_c('p',{staticClass:"error"},[_vm._v(" Minimum Password length "+_vm._s(_vm.$v.fieldsData.value.$params.minLength.min)+" characters ")]):_vm._e()])]):_vm._e(),(_vm.fieldsData.type === 'password' && _vm.fieldsData.use_prefill)?_c('label',{staticClass:"form__row"},[_c('span',{staticClass:"form__row-label"},[_vm._v("Confirm "+_vm._s(_vm.fieldsData.label)+" "),(_vm.fieldsData.required)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form__row-pass"},[((_vm.typePasword)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.password_repeat),expression:"fieldsData.password_repeat"}],staticClass:"input",class:{
          'is-invalid':
            (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.password_repeat)
            || (_vm.fieldsData.required && _vm.$v.fieldsData.password_repeat.$dirty && !_vm.$v.fieldsData.password_repeat.required)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.password_repeat.minLength)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.password_repeat.sameAsPassword)
            ,
          'disabled': _vm.isDisabledEditing
         },attrs:{"placeholder":"******","required":_vm.fieldsData.required,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.fieldsData.password_repeat)?_vm._i(_vm.fieldsData.password_repeat,null)>-1:(_vm.fieldsData.password_repeat)},on:{"input":_vm.validate,"change":function($event){var $$a=_vm.fieldsData.password_repeat,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fieldsData, "password_repeat", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fieldsData, "password_repeat", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fieldsData, "password_repeat", $$c)}}}}):((_vm.typePasword)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.password_repeat),expression:"fieldsData.password_repeat"}],staticClass:"input",class:{
          'is-invalid':
            (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.password_repeat)
            || (_vm.fieldsData.required && _vm.$v.fieldsData.password_repeat.$dirty && !_vm.$v.fieldsData.password_repeat.required)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.password_repeat.minLength)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.password_repeat.sameAsPassword)
            ,
          'disabled': _vm.isDisabledEditing
         },attrs:{"placeholder":"******","required":_vm.fieldsData.required,"type":"radio"},domProps:{"checked":_vm._q(_vm.fieldsData.password_repeat,null)},on:{"input":_vm.validate,"change":function($event){return _vm.$set(_vm.fieldsData, "password_repeat", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldsData.password_repeat),expression:"fieldsData.password_repeat"}],staticClass:"input",class:{
          'is-invalid':
            (_vm.fieldsData.required && _vm.error && !_vm.fieldsData.password_repeat)
            || (_vm.fieldsData.required && _vm.$v.fieldsData.password_repeat.$dirty && !_vm.$v.fieldsData.password_repeat.required)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.password_repeat.minLength)
            || (_vm.$v.fieldsData.value.$dirty && !_vm.$v.fieldsData.password_repeat.sameAsPassword)
            ,
          'disabled': _vm.isDisabledEditing
         },attrs:{"placeholder":"******","required":_vm.fieldsData.required,"type":_vm.typePasword},domProps:{"value":(_vm.fieldsData.password_repeat)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.fieldsData, "password_repeat", $event.target.value)},_vm.validate]}}),_c('span',{staticClass:"form__row-pass-show",on:{"click":function($event){return _vm.showPassword()}}},[_vm._v("Show")]),(_vm.$v.fieldsData.password_repeat.$dirty &&
                          (!_vm.$v.fieldsData.password_repeat.required || !_vm.$v.fieldsData.password_repeat.minLength|| !_vm.$v.fieldsData.password_repeat.sameAsPassword))?_c('p',{staticClass:"error"},[_vm._v(" Enter the same Password ")]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }