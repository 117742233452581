<template>
  <div
    v-if="isShowModal"
    class="terms-and-conditions"
  >
    <span class="terms-and-conditions__close" @click="closeModal"></span>
    <h2 class="terms-and-conditions__title">Terms And Conditions</h2>

    <div
      class="terms-and-conditions__content"
    >
      <p v-html="pageData.content"></p>
    </div>


    <button 
      class="btn btn--color-dark form__button"
      @click="closeModal"
    >
      Ok
    </button>
  </div>
</template>

<script>

import axios from '@axios'

  export default {
    props: {
      isShowModal: {
        type: Boolean,
        default: false,
      },
    },
    data(){
      return {
       pageData: null,
      }
    },
    created() {
      this.fetchPageData()
    },
    methods: {
      closeModal() {
        this.$emit('closeModal')
      },
      async fetchPageData() {
        try {
          this.$emit('loading', true)
          const response = await axios.get('pages-anchors/terms_and_conditions')
          this.pageData = response.data.data
        } finally {
          this.$emit('loading', false)
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.terms-and-conditions {
  position: absolute;
  border-radius: 10px;
  padding: 50px;
  width: 1000px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -20%);
  background: #FFF;
  z-index: 20;
  box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);

  &__close {
    position: absolute;
    display: flex;
    align-items: center;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &::before,&::after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      width: 1px;
      height: 15px;
      background: #000;
      transform: rotate(45deg);
    }
    &::after{
      transform: rotate(-45deg);
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }

  &__content {
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
}
@media screen and (max-width: 767px) {
  .terms-and-conditions {
    position: absolute;
    border-radius: 10px;
    padding: 15px;
    width: 320px;
    left: 50%;
    top: 50%;
    background: #FFF;
    z-index: 20;
    box-shadow:  0px 0px 0px 9999px rgba(0, 0, 0, 0.5);
  }
}
</style>