<template>
  <div>
    <!-- Base Fields -->
    <label
      v-if="showTextField(fieldsData.type)"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <input
        v-model="fieldsData.value"
        :type="fieldsData.type"
        :name="fieldsData.name"
        class="input"
        :disabled="isDisabledEditing"
        :class="{
                'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
                'disabled': isDisabledEditing
              }"
        :required="fieldsData.required"
        @input="validate"
        @change="emitChanges"
      />
    </label>

    <!-- Plain Text -->
    <div
      v-if="fieldsData.type === 'plain_text'"
      class="plain-text"
    >
      <p v-html="fieldsData.label"></p>
    </div>

    <!-- Email -->
    <label
      v-if="fieldsData.type === 'email'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <input
        v-model="fieldsData.value"
        type="email"
        :name="fieldsData.name"
        class="input"
        :disabled="isDisabledEditing"
        :class="{
                'is-invalid':
                   (fieldsData.required && error && !fieldsData.value)
                || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required)
                || ($v.fieldsData.value.$dirty && !$v.fieldsData.value.email)
                ,
                'disabled': isDisabledEditing
              }"
        :required="fieldsData.required"
        @blur="validate"
        @change="emitChanges"
      />
      <p
        v-if="$v.fieldsData.value.$dirty && !$v.fieldsData.value.email"
        class="error"
      >
        Valid email required
      </p>
    </label>

    <!-- Phone Number -->
    <label
      v-if="fieldsData.type === 'tel'"
      class="phone__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <MazPhoneNumberInput
        :value="fieldsData.value"
        class="phone-number"
        no-flags
        :default-phone-number="defaultPhoneNumber"
        :class="{
          'is-invalid':
             (fieldsData.required && error && !fieldsData.value)
          || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required)
          || (enteredPhoneNumber && !enteredPhoneNumber.isValid),
          'disabled': isDisabledEditing
        }"
        :disabled="isDisabledEditing"
        no-validation
        @update="emitPhoneNamberChanges($event)"
      />
      <p
        v-if="enteredPhoneNumber && !enteredPhoneNumber.isValid"
        class="error"
      >
        Valid phone number is required
      </p>
    </label>

    <!-- Long Text -->
    <label
      v-if="fieldsData.type === 'long_text'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <textarea
        v-model="fieldsData.value"
        :required="fieldsData.required"
        :name="fieldsData.name"
        :disabled="isDisabledEditing"
        :class="{
                'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
                'disabled': isDisabledEditing
              }"
        @input="validate"
        @change="emitChanges"
      ></textarea>
    </label>

    <!-- Date -->
    <label
      v-if="fieldsData.type === 'date'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <date-picker
        v-model="fieldsData.value"
        input-class="input-date"
        valueType="format"
        placeholder="Select"
        :disabled="isDisabledEditing"
        :class="{
                'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
                'disabled': isDisabledEditing
              }"
        @input="validate"
        @change="emitChanges"
      ></date-picker>
    </label>


    <!-- Date Of Birth -->
    <label
      v-if="fieldsData.type === 'dob'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <date-picker
        v-model="fieldsData.value"
        input-class="input-date"
        valueType="format"
        placeholder="Select"
        :disabled="isDisabledEditing"
        :disabled-date="(date) => date >= new Date()"
        :class="{
                'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
                'disabled': isDisabledEditing
              }"
        @input="validate"
        @change="emitChanges"
      ></date-picker>
    </label>


    <!-- Dropdown -->
    <label
      v-if="fieldsData.type === 'dropdown'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div class="input-select">
        <select
          v-model="fieldsData.value"
          class="input"
          :disabled="isDisabledEditing"
          :class="{
                  'is-invalid': (fieldsData.required && error && (fieldsData.value == null || fieldsData.value.length == 0)) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
                  'disabled': isDisabledEditing
                }"
          @blur="$v.fieldsData.value.$touch"
          :required="fieldsData.required"
          @change="emitChanges"
        >
          <option
            v-for="(option, index) in fieldsData.options"
            :key="index"
            :value="option.value"
          >{{option.text}}</option>
        </select>
      </div>
    </label>


    <!-- Multi Select -->
    <div
      v-if="fieldsData.type === 'multi_select'"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div>
        <v-select
          class="multiple-select"
          placeholder="Select"
          multiple
          :disabled="isDisabledEditing"
          v-model="fieldsData.value"
          :closeOnSelect="false"
          :options="fieldsData.options"
          label="name"
          :deselectFromDropdown = "true"
          :class="{'is-invalid': (fieldsData.required && error && (fieldsData.value == null || fieldsData.value.length == 0)) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required) }"
          @input="emitChanges"
        >
          <template
            #option="{ name }"
          >
            <span>{{name}}</span>
          </template>
        </v-select>
      </div>
    </div>


    <!-- Checkbox -->
    <div
      v-if="fieldsData.type === 'checkbox'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <label
        v-for="(option, index) in fieldsData.options"
        :key="index"
        class="nice-checkbox"
      >
        <input
          type="checkbox"
          v-model="fieldsData.value"
          :disabled="isDisabledEditing"
          :class="{
            'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
            'disabled': isDisabledEditing
          }"
          :value="option"
          @input="validate"
          @change="emitChanges"
        />
        <span>{{option}}</span>
      </label>
    </div>


    <!-- Radio Button -->
    <div
      v-if="fieldsData.type === 'radio_button'"
      class="form__row"
    >
      <span
        class="form__row-label"
        :class="{
            'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
          }"
      >{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <label
        v-for="(option, index) in fieldsData.options"
        :key="index"
        class="nice-radio"
      >
        <input
          type="radio"
          v-model="fieldsData.value"
          :disabled="isDisabledEditing"
          :class="{
            'is-invalid': (fieldsData.required && error && !fieldsData.value) || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required),
            'disabled': isDisabledEditing
          }"
          :value="option"
          @change="emitChanges"
        />
        <span>{{option}}</span>
      </label>
    </div>


    <!-- File Upload -->
    <div
      v-if="fieldsData.type === 'file_upload'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <file-select
        :fields-data="fieldsData"
        :class="{
          'is-invalid': (fieldsData.required && error && (fieldsData.value == null || fieldsData.value.length === 0)),
          'disabled': isDisabledEditing
        }"
        :disabled="isDisabledEditing"
        @input="validate"
        @addFiles="addFiles"
      />
      <div
        v-if="fieldsData.value && !isDisabledEditing"
        class="files"
      >
        <div
          v-for="(file, index) in fieldsData.value"
          :key="index"
          class="file"
        >
          <FileSVG />
          <span v-if="isFile(file)">{{ file.name }}</span>
          <a :href="findFileUrlByPath(file)" target="_blank" v-else>{{ findFileNameByPath(file) }}</a>
          <span class="file_remove" @click="fileRemove(index)"></span>
        </div>
      </div>
    </div>

    <!-- Signature -->
    <div
      v-if="fieldsData.type === 'signature'"
    >
      <signature
        :ref="'signature_' + fieldsData.ref"
        :fields-data="fieldsData"
        :is-disabled-field="isDisabledEditing"
        :ref-name="fieldsData.ref"
      />
    </div>

    <!-- Password -->
    <label
      v-if="fieldsData.type === 'password'"
      class="form__row"
    >
      <span class="form__row-label">{{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div class="form__row-pass">
        <input
          v-model="fieldsData.value"
          class="input"
          :name="fieldsData.name"
          :type="typePasword"
          :disabled="isDisabledEditing"
          placeholder="******"
          :class="{
            'is-invalid':
              (fieldsData.required && error && !fieldsData.value)
              || (fieldsData.required && $v.fieldsData.value.$dirty && !$v.fieldsData.value.required)
              || ($v.fieldsData.value.$dirty && !$v.fieldsData.value.minLength)
              ,
            'disabled': isDisabledEditing
           }"
          :required="fieldsData.required"
          @input="validate"
          @change="emitChanges"
        />
        <span class="form__row-pass-show" @click="showPassword()">Show</span>
        <p
          v-if="$v.fieldsData.value.$dirty && !$v.fieldsData.value.required"
          class="error"
        >
          The Password field is required
        </p>
        <p
          v-else-if="$v.fieldsData.value.$dirty && !$v.fieldsData.value.minLength"
          class="error"
        >
          Minimum Password length {{$v.fieldsData.value.$params.minLength.min}} characters
        </p>
      </div>
    </label>

    <label
      v-if="fieldsData.type === 'password' && fieldsData.use_prefill"
      class="form__row"
    >
      <span class="form__row-label">Confirm {{fieldsData.label}} <span v-if="fieldsData.required">*</span></span>
      <div class="form__row-pass">
        <input
          v-model="fieldsData.password_repeat"
          class="input"
          :type="typePasword"
          placeholder="******"
          :class="{
            'is-invalid':
              (fieldsData.required && error && !fieldsData.password_repeat)
              || (fieldsData.required && $v.fieldsData.password_repeat.$dirty && !$v.fieldsData.password_repeat.required)
              || ($v.fieldsData.value.$dirty && !$v.fieldsData.password_repeat.minLength)
              || ($v.fieldsData.value.$dirty && !$v.fieldsData.password_repeat.sameAsPassword)
              ,
            'disabled': isDisabledEditing
           }"
          :required="fieldsData.required"
          @input="validate"
        />
        <span class="form__row-pass-show" @click="showPassword()">Show</span>
        <p
          v-if="$v.fieldsData.password_repeat.$dirty &&
                            (!$v.fieldsData.password_repeat.required || !$v.fieldsData.password_repeat.minLength|| !$v.fieldsData.password_repeat.sameAsPassword)"
          class="error"
        >
          Enter the same Password
        </p>
      </div>
    </label>

  </div>
</template>

<script>
import store from '@/store'

import "maz-ui/lib/css/base.css"
import "maz-ui/lib/css/maz-phone-number-input.css"

import { mapGetters } from 'vuex'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/index.css'
import 'vue-select/dist/vue-select.css'

import { MazPhoneNumberInput } from 'maz-ui'
import "maz-ui/lib/css/base.css"
import "maz-ui/lib/css/maz-phone-number-input.css"

import FileSVG from '@/assets/images/icons/icon-file.svg'
import {
  capitalizeFirstLetter,
} from '@/helpers/inputFormater'

import Signature from '@/components/site/Signature.vue'
import FileSelect from '@/components/site/FileSelect.vue'


export default {
  name: 'RegistrationFormRow',
  components: {
    Signature,
    FileSelect,
    FileSVG,

    vSelect,
    DatePicker,

    MazPhoneNumberInput
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {
      },
    },
    isDisabledEditing: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      error: false,
      defaultPhoneNumber: null,
      enteredPhoneNumber: null,
      typePasword: 'password',
    }
  },
  validTracker(val) {
    if (val > 0 && !this.value?.length) this.error = true
  },
  computed: {
    ...mapGetters({
      familyId: 'family/getFamilyId',
      authSettings: 'authSettings/getAuthSettings',
      roles: 'registration/getRoles',
      validTracker: 'registration/getValidTracker',
    }),
    serverMessage: {
      get: function () {
        return store.getters['registration/getServerMessage']
      },
      set: function (val) {
        store.commit('registration/SET_SERVER_MESSAGE', val)
      }
    }
  },
  validations() {
    if (this.fieldsData.type === 'email') {
      return {
        fieldsData: {
          value: {
            required,
            email,
          }
        }
      }
    } else if (this.fieldsData.type === 'password' && !this.fieldsData.use_prefill) {
      return {
        fieldsData: {
          value: {
            required,
            minLength: minLength(6),
          }
        }
      }
    } else if (this.fieldsData.type === 'password' && this.fieldsData.use_prefill) {
      return {
        fieldsData: {
          value: {
            required,
            minLength: minLength(6),
          },
          password_repeat: {
            required,
            minLength: minLength(6),
            sameAsPassword: sameAs('value'),
          }
        }
      }
    } else {
      return {
        fieldsData: {
          value: {
            required,
          }
        }
      }
    }
  },
  methods: {
    showTextField(type) {
      return ~['text', 'number'].findIndex(item => item === type)
    },
    async validate() {
      if (this.fieldsData.type === 'text') {
        this.fieldsData.value = capitalizeFirstLetter(this.fieldsData.value)
        this.emitChanges()
      }
      if (this.$v.$invalid) {
        await this.$v.$touch();
      }
    },
    showPassword() {
      if (this.typePasword === 'password') {
        this.typePasword = 'text'
      } else {
        this.typePasword = 'password'
      }
    },
    emitPhoneNamberChanges(e) {
      if (e.formatInternational) {
        this.enteredPhoneNumber = e
        this.fieldsData.value = e.formatInternational
        this.emitChanges()
      }
    },
    isFile(item) {
      return item instanceof Blob
    },
    fileRemove(index) {
      this.fieldsData.value.splice(index, 1)
    },
    findFileUrlByPath(path) {
      if (!this.fieldsData.files) {
        return ''
      }
      return this.fieldsData.files.filter(file => file.path === path)[0]?.url
    },
    addFiles(files) {
      this.fieldsData.value.push(...Array.from(files))
    },
    findFileNameByPath(path) {
      if (!this.fieldsData.files) {
        return ''
      }
      return this.fieldsData.files.filter(file => file.path === path)[0]?.path
    },
    emitChanges() {
      if (this.fieldsData.type === 'email') {
        this.serverMessage = ''
      }
      store.commit('registration/CHECK_CONDITIONS')
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/colors/colors';

.mx-datepicker{
  &.is-invalid{
    .input-date {
      border: 1px solid var(--error) !important;
      &::placeholder {
        color: var(--error)
      }
    }
  }
  &.disabled{
    .input-date{
      cursor: not-allowed;
      background-color: #cccccc;
      opacity: 0.7;
    }
  }

}
.multiple-select{
  margin-bottom: 30px;
}
.plain-text{
  margin: 30px 0;
  word-break: break-word;
  h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, a, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: revert;
    padding: revert;
    font-size: revert;
    list-style: revert;
  }
  img, video {
    max-width: 100%;
  }
}
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.file {
  padding: 10px;
  position: relative;
  width: 32%;
  word-break: break-all;
  a {
    color: $secondary;
  }
  svg {
    height: 20px;
  }
  svg path, svg rect {
    fill: $primary;
  }
  &_remove {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    cursor: pointer;

    &::before,&::after {
      content: '';
      display: block;
      position: absolute;
      left: 6px;
      width: 1px;
      height: 15px;
      background: $secondary;
      transform: rotate(45deg);
    }
    &::after{
      transform: rotate(-45deg);
    }
  }

}
.phone__row{
  width: 100%;
  margin-bottom: 30px;
}
.phone-number {
  border-radius: 12px;
  .maz-input {
    height: 60px;
    background: #EDEDED;
    border: none !important;
    border-color: none !important;
  }
  .maz-input__toggle-btn {
    display: none;
  }
  &.is-invalid{
    border: 1px solid var(--error) !important;
    &::placeholder {
      color: var(--error)
    }
  }
}
</style>
<style scoped>
.error {
  position: absolute;
  color: red;
  font-size: 14px;
}
</style>
